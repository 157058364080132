@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", serif !important;
}

body {
    background-color: #f1f1f1;
    font-size: 14px;
    overflow-x: hidden;
}

.bg-primary {
    background-color: #1C5838 !important;
    color: #FFFFFF;
}

.bg-gray {
    background-color: #f1f1f1;
}

a {
    text-decoration: none;
    color: #0DC31F;
}

/*.btn-primary {*/
/*    border: 0;*/
/*    font-weight: normal;*/
/*    background: #DF901F;*/
/*    padding-left: 15px;*/
/*    padding-right: 15px;*/
/*    padding-top: 6px;*/
/*    padding-bottom: 6px;*/
/*    color: white;*/
/*    font-size: 12px;*/
/*}*/

.btn-primary {
    background: #DF901F;
    color: white;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-primary:disabled {
    background: #b3b3b3;
    color: #f1f1f1;
    cursor: not-allowed;
    opacity: 0.65;
}

.btn-primary:hover {
    background-color: #1C5838;
    border-color: #1C5838;
}

.bg-orange {
    background: #DF901F;
    color: #FFFFFF;
}

.btn-gray {
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.text-orange {
    color: #DF901F;
}

.text-green {
    color: #1C5838;
}

.text-gray {
    color: #5F6368;
}

.text-small {
    font-size: 12px;
}

.text-lightgreen {
    color: #7AC943;
}

.nav-item-active {
    background-color: rgba(0, 0, 0, 0.28);
}

.nav .nav-item {
    background-color: #FFFFFF;
    border-radius: 40px;
}

.nav .nav-item a {
    color: black;
}

/*.nav .nav-item:hover,*/
.nav .nav-item.active {
    background-color: #1C5838;
}

.navbar-nav .nav-item:hover,
.navbar-nav .nav-item.active,
.navbar-nav .nav-item.active a,
.navbar-nav .nav-item:hover a {
    color: #1C5838 !important;
}

.action-buttons button {
    border-radius: 5px;
    min-width: 30px;
    border-width: 1px;
    border-color: #d9d9d9;
}

.action-buttons a {
    border-radius: 5px;
    min-width: 30px;
    border-width: 1px;
    border-color: #d9d9d9;
}

.card-action-buttons {
    background-color: #FFFFFF;
    border-radius: 6px;
    min-width: 25px;
    border: 1px solid #d9d9d9;
    /*margin-left: 210px;*/
}

.table>tbody tr.selected>td {
    background-color: #fcd6ab;
}

.clickable-row {
    cursor: pointer;
}

.page-title a,
.page-title span {
    font-size: 1em;
    font-weight: 500;
}

.nav-underline .nav-item {
    padding: 0px;
    font-size: smaller;
}

.nav-underline .nav-link {
    color: inherit;
}

.nav-underline .nav-link.active,
.nav-underline .nav-link:hover,
.nav-underline .show>.nav-link {
    color: #0DC31F;
    font-weight: 400;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    /*background-color: rgba(217, 217, 217, 0.2);*/
}

.disabled {
    border: 0 !important;
}

.details-pane {
    padding: 15px;
    background-color: white;
    position: fixed;
    top: 0px;
    /*overflow-y: auto;*/
    right: 0;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
}

.details-pane.visible {
    transform: translateX(0);
}

.form-check-input:checked {
    background-color: #28a745;
    /* Change this to your desired color */
    border-color: #28a745;
    /* Change this to your desired color */
    background-color: #28a745;
    /* Change this to your desired color */
    border-color: #28a745;
    /* Change this to your desired color */
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.custom-checkbox .x-radio-group {
    background-color: #FFFFFF;
}

.x-radio-group:hover {
    background-color: #f1f1f1;
}

.side-menu {
    padding: 0;
    margin: 0;
}

.side-menu li {
    list-style: none;
    margin-bottom: 10px;
}

.side-menu li button {
    border: 0;
    padding: 10px 15px;
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    text-align: left;
}

.side-menu li button:hover,
.side-menu li button.active {
    background-color: #d3d3d3;
}

.w-35 {
    width: 35% !important;
}

.small {
    font-size: 10px;
}

th,
td {
    font-size: 1em;
}

.marker-tr .row {
    width: inherit;
}

.bg-black {
    z-index: 1;
}

.modal-body label {
    font-size: 14px !important;
}

.modal-body input {
    font-size: 12px !important;
}

option {
    font-size: 12px !important;
}

.bg-black span {
    font-size: 12px !important;
}

.input-group input {
    font-size: 12px !important;
}

.btn span {
    font-size: 12px !important;
}

.btn-minus {
    background: none;
    border: none;
    color: red;
}

.btn-plus {
    background: none;
    border: none;
    color: green;
}

.form-control p {
    font-size: 12px !important;
}

.badge-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.badge {
    font-weight: 400;
    border-radius: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-bg-purple-light {
    background-color: #AA0050;
    color: white;
}

.text-bg-purple {
    background-color: #5F1ED9;
    color: white;
}

.input-card {
    height: 130px;
    /* Set a fixed height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* For positioning the badge */
    border-radius: 8px;
}

.centre-card {
    border-radius: 8px;
    height: 100px;
}

.centre-container {
    position: absolute;
    top: 8px;
    right: 165px;
}

.input-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #EAEBEB;
    border: 1px solid #1C5838;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.badge-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #EAEBEB;
    border: 1px solid #1C5838;
}

.danger-badge {
    font-size: 0.65rem;
    border-radius: 8px;
    padding: 2px 4px;
    background-color: #FFD8DC;
    color: #D0021B;
    border: 1px solid #D0021B;
}

.warning-badge {
    font-size: 0.65rem;
    border-radius: 8px;
    padding: 2px 4px;
    background-color: #FFF4E5;
    color: #DF901F;
    border: 1px solid #DF901F;
}

.success-badge {
    font-size: 0.65rem;
    border-radius: 8px;
    padding: 2px 4px;
    background-color: #CDF9E2;
    color: #1C5838;
    border: 1px solid #1C5838;
}

.card-gray {
    padding: 8px;
    box-shadow: #F1F1F1 0 0 10px;
    border-radius: 8px;
    background-color: #FFFFFF;
    height: 100%;
}

.btn-secondary {
    background-color: #EFF1F0;
    border: 0;
    color: black;
}

.btn-outline-primary {
    color: #DF901F;
    border: 1px solid #DF901F !important;
}

.btn-outline-primary:hover {
    background-color: #1C5838;
    border: 1px solid #1C5838 !important;
}

.contact-card h5 {
    font-size: 12px !important;
}

.contact-card p {
    font-size: 10px !important;
    margin-bottom: 8px;
}

.contact-icon {
    border: 1px solid #D9D9D9;
    border-radius: 100%;
    background-color: #1C5838;
    color: white;
    padding: 15px;
    position: relative;
    width: 50px;
    height: 50px;
    top: -1.5rem;
}

span {
    font-size: 12px;
}

.fs-4 {
    font-size: 0.8rem !important;
}

@media (min-width: 1200px) {

    .h1,
    h1 {
        font-size: 1.5rem;
    }
}

.min-vh-50 {
    min-height: 44vh !important;
}

.img-holder {
    height: 6rem;
    width: 8rem;
}

.text-muted,
button,
a,
p {
    font-size: 12px !important;
}

.back-button {
    border: none;
    width: 1.1rem;
    margin-right: 8px;
}

.scrollable-content {
    max-height: 40vh;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    border: 1px solid #F5F5F5;
    border-radius: 24px;
    outline: none;
    transition: background 0.3s;
}

.custom-checkbox:checked {
    background-color: #DF901F;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-checkbox:checked::after {
    content: '✓';
    font-size: 14px;
}

.custom-checkbox:hover {
    border-color: #1C5838;
}

.custom-checkbox:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.checkbox-span {
    margin-left: 10px;
}

.form-check-input {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #007bff;
    background-color: white;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
}

.form-check-input:checked+.form-check-label {
    color: #007bff;
}

.form-check-input {
    accent-color: #007bff;
}

.progress-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.progress-label {
    font-size: 12px;
    font-weight: 500;
    color: #616162;
    padding-left: 5px;
}

.custom-graph-legend {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}

.breadcrumb .breadcrumb-item {
    text-transform: uppercase;
}

.breadcrumb .breadcrumb-item:last-child span {
    font-weight: bold;
}

.scrollable-sidebar {
    max-height: 80vh;
    overflow-x: auto;
    padding-right: 10px;
    padding-bottom: 50px;
}

.search-page.center-aligned {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
    background-color: white;
    height: 65vh;
}

.search-page.top-aligned {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: white;
    height: auto;
}

.search-bar {
    width: 450px;
    text-align: center;
}

.batched-status {
    background-color: #DFF9FA;
    border: 1px solid #33C5CD;
    border-radius: 8px;
    color: #33C5CD;
    padding: 5px;
}

.intransit-status {
    background-color: #D0E3FB;
    border: 1px solid #043369;
    border-radius: 8px;
    color: #043369;
    padding: 5px;
}

.delivered-status {
    background-color: #CDF9E2;
    border: 1px solid #1C5838;
    border-radius: 8px;
    color: #1C5838;
    padding: 5px;
}

.exported-status {
    background-color: #CAEFFF;
    border: 1px solid #1138C0;
    border-radius: 8px;
    color: #1138C0;
    padding: 5px;
}

.processing-status {
    background-color: #FFF4E5;
    border: 1px solid #DF901F;
    border-radius: 8px;
    color: #DF901F;
    padding: 5px;
}

.processed-status {
    background-color: #C9F8DF;
    border: 1px solid #1C5838;
    border-radius: 8px;
    color: #1C5838;
    padding: 5px;
}

.form-error {
    color: #d32f2f;
    font-size: 12px;
    margin-top: -20px;
}